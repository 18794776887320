<template lang="pug">
  .wrapper
    header
      .logo
      //el-menu(:default-active="activeIndex" mode="horizontal" @select="handleSelect")
        el-menu-item(index="1") 资讯中心
    .content
      section
        a(href="https://shop.woxiaojiang.com")
          img(src="https://oss.woxiaojiang.com/oss/snails/banner/1ddeeb40-63bd-11ec-96ee-f1c2fec9db60.jpeg?x-oss-process=image/resize,w_300")
      section
        a(href="https://shop.woxiaojiang.com")
          img(src="https://oss.woxiaojiang.com/oss/snails/banner/806cc3f0-63a8-11ec-934e-b7cae0b1e705.jpg?x-oss-process=image/resize,w_300")
      section
        a(href="https://shop.woxiaojiang.com")
          img(src="https://oss.woxiaojiang.com/oss/snails/banner/06fc4e90-63bd-11ec-96ee-f1c2fec9db60.jpeg?x-oss-process=image/resize,w_300")
      section
        a(href="https://shop.woxiaojiang.com")
          img(src="https://oss.woxiaojiang.com/oss/snails/banner/9a4a2a10-63a8-11ec-934e-b7cae0b1e705.jpg?x-oss-process=image/resize,w_300")
    pageFooter
</template>

<script>
  const pageFooter = () => import('@/components/pageFooter')
  export default {
    components: {
      pageFooter
    },
    data() {
      return {
        activeIndex: '1'
      }
    },
    methods: {
      handleSelect() {
        this.$router.push('/information')
      }
    }
  }
</script>

<style lang="less" scoped>
  .wrapper {
    position: relative;
    width: 100%;
    margin: 0 auto;
    header {
      display: flex;
      justify-content: space-between;
      padding: .1rem;
      width: 100%;
      color: #42b983;
      text-align: left;
      .logo {
        display: inline-block;
        width: 1rem;
        height: .5rem;
        background: url(https://www.woxiaojiang.com/oss/sources/logo.png) no-repeat;
        background-size: contain;
      }
      /deep/ .el-menu--horizontal> .el-menu-item {
        height: .4rem;
        line-height: .4rem;
      }
    }
    .content {
      padding-bottom: 1rem;
      width: 100%;
      min-height: 5rem;
      section {
        box-sizing: border-box;
        padding: .2rem;
        width: 100%;
        background: white;
        img {
          box-shadow: 0 0 .05rem rgba(0,0,0,.8);
          border: .05rem solid white;
        }
        &:nth-of-type(odd) {
          text-align: right;
          img {
            transform: rotate(-10deg);
          }
        }
        &:nth-of-type(even) {
          img {
            transform: rotate(10deg);
          }
        }
      }
    }
  }
  @media (min-width:320px)  {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    .wrapper {
      width: 100%;
      font-size: .12rem;
      header {
        height: .8rem;
      }
    }
  }
  @media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ }
  @media (min-width:600px)  {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .wrapper {
      width: 6rem;
      header {
        height: 1rem;
      }
    }
  }
  @media (min-width:801px)  {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    .wrapper {
      width: 8rem;
    }
  }
  @media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
  @media (min-width:1281px) { /* hi-res laptops and desktops */ }
</style>
